import { Vue, Component } from "vue-property-decorator";

@Component({
  inheritAttrs: false,
})
export default class CardIssueLimitMenu extends Vue {
  private get localListeners() {
    const listeners = { ...this.$listeners };

    delete listeners.success;
    delete listeners.close;

    return listeners;
  }

  private onSucces<T>(value: T, callback: () => void) {
    callback();

    this.$emit("success", value);
  }
}
